var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return undefined},proxy:true}])},[_c('h4',{staticClass:"px-4 py-4"},[_c('b',[_vm._v("información de la nómina")])]),_c('div',{staticClass:"row px-5 mb-4"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label",attrs:{"for":" "}},[_vm._v(" Período de Liquidación ")]),_c('div',[_c('label',{staticClass:"form-label",attrs:{"for":" "}},[_vm._v("Fecha inicio")]),_c('input',{staticClass:"form-control",attrs:{"readonly":"","required":"","placeholder":"","type":"date","id":" "},domProps:{"value":_vm.nominaLiquidacion?.fechaInicio}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"form-label",attrs:{"for":" "}},[_vm._v("Fecha fin")]),_c('input',{staticClass:"form-control",attrs:{"readonly":"","required":"","placeholder":"","type":"date","id":" "},domProps:{"value":_vm.nominaLiquidacion?.fechaFin}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"form-label",attrs:{"for":" "}},[_vm._v("Tipo de Liquidación")]),_c('input',{staticClass:"form-control",attrs:{"disabled":"","required":"","placeholder":"","type":"text","id":" "},domProps:{"value":_vm.nominaLiquidacion?.tipoLiquidacion == 1
                ? 'Primer semestre'
                : _vm.nominaLiquidacion?.tipoLiquidacion == 2
                ? 'Último semestre / Navideña'
                : 'Nómina'}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"form-label",attrs:{"for":" "}},[_vm._v("Estado")]),_c('input',{staticClass:"form-control",attrs:{"disabled":"","required":"","placeholder":"","type":"text","id":" "},domProps:{"value":_vm.nominaLiquidacion?.estado == 1 ? 'Liquidado' : ''}})])])])]),_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1)])]),_c('b-card',[_c('div',[_c('div',{staticClass:"border border-1 border-white p-2 rounded rounded-2"},[_c('div',{staticClass:"py-3 pb-1"},[(
              _vm.nominaLiquidacion &&
              !_vm.nominaLiquidacion.idSeguimientoAportesEnLinea
            )?_c('vs-button',{attrs:{"gradient":""},on:{"click":_vm.enviarAportesEnLinea}},[_vm._v("Transmitir nomina")]):_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageEstados),expression:"messageEstados"}],staticClass:"bg-primary text-white p-3 rounded"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(/^[0-9]+$/.test(_vm.messageEstados.substring())),expression:"/^[0-9]+$/.test(messageEstados.substring())"}],staticClass:"fas fa-spinner fa-spin text-warning pr-2"}),_vm._v(" "+_vm._s(_vm.messageEstados)+" ")])])],1),_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"dataTableEmpeladosLiquidados"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Identificación")]),_c('th',[_vm._v("Salario")]),_c('th',[_vm._v("Pagos adicionales")]),_c('th',[_vm._v("Deducciones")]),_c('th',[_vm._v("Total a pagar")]),_c('th',[_vm._v("Opciones")]),_c('th',[_vm._v("Estado")])])]),_c('tbody',_vm._l((_vm.empleadosLiquidados),function(liquidacionPersona){return _c('tr',{key:liquidacionPersona.dataId},[_c('td',[_vm._v(_vm._s(_vm._f("nombreCompleto")(liquidacionPersona)))]),_c('td',[_vm._v(_vm._s(liquidacionPersona.persona.numeroDocumento))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatoMoneda")(liquidacionPersona.persona.salario))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatoMoneda")(liquidacionPersona.totalIngresosAdicionales))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatoMoneda")(liquidacionPersona.totalDeducciones))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatoMoneda")(liquidacionPersona.totalPago)))]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"0.25rem"}},[_c('vs-button',{attrs:{"success":"","icon":""},on:{"click":function($event){return _vm.mostrarDestallesLiquidacionPersona(liquidacionPersona)}}},[_c('i',{staticClass:"fas fa-solid fa-eye"})]),_c('router-link',{attrs:{"to":{
                      path: `/helex/liquidacioneditardetalle/${_vm.liquidacionId}/${liquidacionPersona.persona.id}`,
                    }}},[_c('vs-button',{attrs:{"success":"","icon":""}},[_c('i',{staticClass:"fas fa-solid fa-file"})])],1),_c('vs-button',{attrs:{"warn":"","icon":""}},[(liquidacionPersona.estadoAportes == 'Aprobado')?_c('i',{staticClass:"fas fa-solid fa-cog pointer pointer",on:{"click":function($event){return _vm.mostrarNominaDeAjuste(liquidacionPersona)}}}):_c('i',{staticClass:"fas fa-solid fa-cog"})])],1)]),_c('td',[_c('span',{on:{"click":function($event){return _vm.mostrarModalConsultaEstado(liquidacionPersona)}}},[(liquidacionPersona.estadoAportes == 'Aprobado')?_c('i',{staticClass:"fas fa-solid fa-check px-1 text-success pointer"}):(
                      liquidacionPersona.estadoAportes == 'Rechazado'
                    )?_c('i',{staticClass:"fas fa-solid fa-exclamation-triangle px-1 text-danger pointer"}):(liquidacionPersona.estadoAportes == null)?_c('i',{staticClass:"fas fa-solid fa-minus px-1",staticStyle:{"color":"gray"}}):_c('i',{staticClass:"fas fa-solid fa-clock px-1 text-warning pointer"})])])])}),0)])])])]),_c('b-modal',{attrs:{"title":"Prevalidaciones","id":"modalPrevalidacionesPersona","size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"default",fn:function({ close }){return [(_vm.onActiveModalDataLiquidacionPersona)?_c('div',{staticClass:"mb-3"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm._f("nombreCompleto")(_vm.onActiveModalDataLiquidacionPersona)))]),_c('p',[_vm._v(" "+_vm._s(_vm.onActiveModalDataLiquidacionPersona.persona.numeroDocumento)+" ")])]),(_vm.onActiveModalDataLiquidacionPersona.error)?_c('div',{staticClass:"border-1 border border-white p-3"},[_c('p',[_vm._v("Datos faltantes de la persona")]),_c('ul',{staticClass:"p-0"},_vm._l((_vm.mapError(
                _vm.onActiveModalDataLiquidacionPersona.error
              )),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_c('div',[_c('p',[_vm._v("Todo en orden")])])]):_vm._e(),_c('div',{staticClass:"w-100 d-flex flex-row-reverse"},[_c('vs-button',{attrs:{"gradient":"","success":""},on:{"click":close}},[_vm._v(" Cerrar ")]),_c('router-link',{attrs:{"target":"_blank","to":{
            path: `/helex/Persona_editar/${_vm.onActiveModalDataLiquidacionPersona.persona.id}`,
          }}},[_c('vs-button',{attrs:{"gradient":""}},[_vm._v("Ir al perfil")])],1)],1)]}}])}),_c('b-modal',{attrs:{"title":"Liquidación","id":"modalDetallesLiquidacionPersona","size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"default",fn:function({ close }){return [(_vm.onActiveModalDataLiquidacionPersona)?_c('ModalLiquidacionPersonaDetalles',{attrs:{"nominaLiquidacionPersonaDetalle":_vm.onActiveModalDataLiquidacionPersona.nominaLiquidacionPersonaDetalle}}):_vm._e(),_c('div',{staticClass:"w-100 text-right"},[_c('button',{staticClass:"mx-1 btn px-4 py-2 btn-success",on:{"click":close}},[_vm._v(" Cerrar ")])])]}}])}),_c('b-modal',{attrs:{"title":"Nomina de ajuste","id":"modalNominaDeAjuste","size":"lg","hide-footer":""}},[(_vm.onActiveModalDataLiquidacionPersona)?_c('NominaAjustePersona',{attrs:{"liquidacionId":_vm.liquidacionId,"liquidacionPersonaId":_vm.onActiveModalDataLiquidacionPersona.id}}):_vm._e()],1),_c('b-modal',{attrs:{"title":"Consultar estado","id":"modalConsultaDeEstado","size":"md","hide-footer":""}},[(_vm.onActiveModalDataLiquidacionPersona)?_c('NominaConsultaEstados',{attrs:{"liquidacionPersonaId":_vm.onActiveModalDataLiquidacionPersona.id}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }